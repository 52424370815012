import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import { Modal, Button, Card, Container, Row, Col, Form } from 'react-bootstrap';
import axios from 'axios';

const socket = io('https://testapi.abulfaris.in', { transports: ['websocket'] });

const App = () => {
  // State definitions
  const [movies, setMovies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [showPosterModal, setShowPosterModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSeedrModal, setShowSeedrModal] = useState(false);
  const [seedrSizes, setSeedrSizes] = useState([]);
  const [fileLink, setFileLink] = useState('');
  const [isAddingToSeedr, setIsAddingToSeedr] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [backdrops, setBackdrops] = useState([]);
  const [currentBackdrop, setCurrentBackdrop] = useState(0);
  const [currentProcessingMovie, setCurrentProcessingMovie] = useState(null);
  const [copiedLink, setCopiedLink] = useState(null);
  const [processingStage, setProcessingStage] = useState('');
  const [fileExists, setFileExists] = useState(false);
  const [isCheckingFile, setIsCheckingFile] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false); // New state for deletion loading

  useEffect(() => {
    fetchMovies(currentPage, searchTerm);
    fetchBackdrops();

    socket.on('newMovie', (movie) => {
      setMovies((prevMovies) => [movie, ...prevMovies]);
    });

    socket.on('updateMovie', (updatedMovie) => {
      setMovies((prevMovies) =>
        prevMovies.map((movie) => (movie._id === updatedMovie._id ? updatedMovie : movie))
      );
    });

    return () => {
      socket.off('newMovie');
      socket.off('updateMovie');
    };
  }, [currentPage, searchTerm]);

  useEffect(() => {
    if (backdrops.length > 0) {
      const interval = setInterval(() => {
        setCurrentBackdrop((prev) => (prev + 1) % backdrops.length);
      }, 10000); // Change the backdrop every 10 seconds

      return () => clearInterval(interval);
    }
  }, [backdrops]);

  useEffect(() => {
    if (isAddingToSeedr) {
      const stages = [
        'Casting actors...',
        'Finalizing the script...',
        'Scouting locations...',
        'Setting up the scenes...',
        'Filming the first scene...',
        'Wrapping up the final shot...'
    ];
  
      let stageIndex = 0;
      
      // Set the first stage immediately
      setProcessingStage(stages[stageIndex]);
      stageIndex++;
  
      const interval = setInterval(() => {
        if (stageIndex < stages.length) {
          setProcessingStage(stages[stageIndex]);
          stageIndex++;
        } else {
          clearInterval(interval);
        }
      }, 3500); // Change message every 3.5 seconds for demo purposes
  
      return () => clearInterval(interval);
    }
  }, [isAddingToSeedr]);

  const parseReleaseDate = (dateStr) => {
    if (dateStr === 'N/A') return new Date(0);

    const parsedDate = new Date(dateStr);
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate;
    }

    const date = new Date(dateStr);
    return isNaN(date.getTime()) ? new Date(0) : date;
  };

  const fetchMovies = async (page, query) => {
    try {
      const response = await axios.get('https://testapi.abulfaris.in/movies/search', {
        params: { page, limit: 12, query }
      });
      let movies = response.data.movies;

      movies = movies.map(movie => ({
        ...movie,
        releaseDate: parseReleaseDate(movie.releaseDate)
      }));

      movies.sort((a, b) => b.releaseDate - a.releaseDate);

      setMovies(movies);
      setTotalPages(Math.ceil(response.data.totalMovies / 12));
    } catch (error) {
      console.error('Error fetching movies:', error.message);
    }
  };

  const fetchBackdrops = async () => {
    try {
      const response = await axios.get('https://testapi.abulfaris.in/movies/backdrops');
      setBackdrops(response.data);
    } catch (error) {
      console.error('Error fetching backdrops:', error.message);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchMovies(pageNumber, searchTerm);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDownloadClick = (movie) => {
    setSelectedMovie(movie);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMovie(null);
  };

  const handlePosterClick = (movie) => {
    setSelectedMovie(movie);
    setShowPosterModal(true);
  };

  const handleClosePosterModal = () => {
    setShowPosterModal(false);
    setSelectedMovie(null);
  };

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
    setCurrentPage(1);
    fetchMovies(1, newSearchTerm);
  };

  const handleAddToSeedr = (movie) => {
    setSelectedMovie(movie);
    setShowSeedrModal(true);
    setIsCheckingFile(true);

    // Perform file existence check after the modal is shown
    setTimeout(async () => {
      try {
        const response = await axios.post('https://testapi.abulfaris.in/seedr/check-file-existence', {
          magnetLink: movie.links.find(link => parseFloat(link.size) < 3.5)?.link
        });

        if (response.data.spaceUsed > 0) {
          setFileExists(true);
        } else {
          setSeedrSizes(movie.links
            .filter(link => parseFloat(link.size) < 3.5)
            .map(link => link.size)
          );
          setFileLink('');
          setFileExists(false);
        }
      } catch (error) {
        console.error('Error checking file existence:', error.message);
      } finally {
        setIsCheckingFile(false);
      }
    }, 500); // Delay to ensure modal opens first
  };

  const handleDeleteFile = async () => {
    if (!selectedMovie) return;

    setIsDeleting(true); // Start loading animation
    try {
      await axios.post('https://testapi.abulfaris.in/seedr/delete', {
        magnetLink: selectedMovie.links.find(link => parseFloat(link.size) < 3.5)?.link
      });

      // After deleting, fetch available sizes again
      setSeedrSizes(selectedMovie.links
        .filter(link => parseFloat(link.size) < 3.5)
        .map(link => link.size)
      );
      setFileLink('');
      setFileExists(false);
    } catch (error) {
      console.error('Error deleting file:', error.message);
    } finally {
      setIsDeleting(false); // Stop loading animation
    }
  };

  const handleSizeClick = async (size) => {
    if (isAddingToSeedr) return;

    setIsAddingToSeedr(true);
    setCurrentProcessingMovie(selectedMovie);

    try {
      const selectedLink = selectedMovie.links.find(link => link.size === size);
      if (selectedLink) {
        const response = await axios.post('https://testapi.abulfaris.in/seedr/add', {
          magnetLink: selectedLink.link,
          size: parseFloat(size),
        });

        if (response.data.fileLink) {
          setFileLink(response.data.fileLink);
        } else {
          console.error('No file link received from server.');
        }
      } else {
        console.error('No link found for the selected size.');
      }
    } catch (error) {
      console.error('Error adding to Seedr:', error.message);
    } finally {
      setIsAddingToSeedr(false);
    }
  };

  const handleCloseSeedrModal = () => {
    setShowSeedrModal(false);
    setCurrentProcessingMovie(null);
    setFileLink('');
    setProcessingStage('');
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const totalPagesToShow = 5;
    let startPage = Math.max(1, currentPage - Math.floor(totalPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

    if (endPage - startPage < totalPagesToShow - 1) {
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <nav>
        <ul className="pagination">
          {currentPage > 1 && (
            <li className="page-item">
              <Button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                <i className="fas fa-chevron-left"></i>
              </Button>
            </li>
          )}
          {pageNumbers.map((number) => (
            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
              <Button className="page-link mx-1" onClick={() => handlePageChange(number)}>
                {number}
              </Button>
            </li>
          ))}
          {currentPage < totalPages && (
            <li className="page-item">
              <Button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                <i className="fas fa-chevron-right"></i>
              </Button>
            </li>
          )}
        </ul>
      </nav>
    );
  };

  return (
    <>
      <div className="background-image-container">
        {backdrops.map((backdrop, index) => (
          <div
            key={index}
            className={`background-image ${currentBackdrop === index ? 'active' : ''}`}
            style={{ backgroundImage: `url(${backdrop})` }}
          ></div>
        ))}
      </div>
      <Container className="mt-5">
        <img src="/assets/logo.png" alt="YourFlix Tamil Logo" className="logo mb-4" />
        <Form.Control
          type="text"
          placeholder="Search for movies..."
          className="search-bar mb-4"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <Row>
          {movies.map((movie) => (
            <Col key={movie._id} md={4} className="mb-4">
              <Card className="movie-card">
                <Card.Img
                  variant="top"
                  src={movie.poster}
                  className="movie-poster"
                  onClick={() => handlePosterClick(movie)}
                  style={{ cursor: 'pointer' }}
                />
                <Card.Body>
                  <Card.Title className="movie-title">{movie.name} ({movie.year})</Card.Title>
                  <div className="action-buttons-container">
                    <Button
                      variant="primary"
                      onClick={() => handleDownloadClick(movie)}
                      className="download-button mb-2"
                    >
                      Magnet Links
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => handleAddToSeedr(movie)}
                      className="add-to-seedr-button"
                    >
                      Direct Link
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {renderPagination()}
      </Container>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header>
          <Modal.Title>{selectedMovie?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedMovie?.links.length ? (
            <div className="d-flex flex-column align-items-center">
              {selectedMovie.links.map((link) => (
                <Button
                  key={link.size}
                  variant="secondary"
                  onClick={() => {
                    navigator.clipboard.writeText(link.link);
                    setCopiedLink(link.link);
                    setTimeout(() => setCopiedLink(null), 2000);
                  }}
                  className="size-button mb-2"
                >
                  {copiedLink === link.link ? 'Copied!' : link.size}
                </Button>
              ))}
            </div>
          ) : (
            <p className="text-center">No magnet links available for this movie.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="close-button" variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPosterModal} onHide={handleClosePosterModal} centered>
        <Modal.Header>
          <Modal.Title>{selectedMovie?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={selectedMovie?.poster}
            alt={selectedMovie?.name}
            className="img-fluid mb-3 poster-modal-img"
          />
          {selectedMovie?.synopsis && <p>{selectedMovie.synopsis}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button className="close-button" variant="secondary" onClick={handleClosePosterModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal 
        show={showSeedrModal} 
        onHide={isAddingToSeedr ? null : handleCloseSeedrModal} 
        centered 
        backdrop={isAddingToSeedr ? 'static' : true} 
        keyboard={!isAddingToSeedr}
      >
        <Modal.Header closeButton={!isAddingToSeedr}>
          <Modal.Title>{selectedMovie?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isCheckingFile ? (
            <div className="text-center">
              <p>Please wait, analyzing...</p>
            </div>
          ) : isAddingToSeedr ? (
            <div className="text-center">
              <p>{processingStage}</p>
              <p className="text-warning">DO NOT CLOSE!</p>
            </div>
          ) : fileExists ? (
            <div className="d-flex flex-column align-items-center">
              <p>Some file already exists. Delete it to continue.</p>
              <Button
                variant="danger"
                onClick={handleDeleteFile}
                className="delete-button mb-2"
                disabled={isDeleting} // Disable button during loading
              >
                {isDeleting ? (
                  <div className="d-flex align-items-center">
                    <div className="spinner"></div>
                    <span className="loading-text">Deleting...</span>
                  </div>
                ) : (
                  'Delete File'
                )}
              </Button>
              <p className="text-warning mt-2">
                Note: If you are currently downloading any file from here and you delete it, the download will stop unexpectedly. Please be aware.
              </p>
            </div>
          ) : fileLink ? (
            <div className="d-flex flex-column align-items-center">
              <Button
                variant="success"
                href={fileLink}
                target="_blank"
                className="download-button mb-2"
              >
                Download Link
              </Button>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center">
              {seedrSizes.length ? (
                seedrSizes.map((size) => (
                  <Button
                    key={size}
                    variant="primary"
                    onClick={() => handleSizeClick(size)}
                    disabled={isAddingToSeedr && currentProcessingMovie?._id === selectedMovie?._id}
                    className="size-button mb-2"
                  >
                    {isAddingToSeedr && currentProcessingMovie?._id === selectedMovie?._id ? 'Processing...' : size}
                  </Button>
                ))
              ) : (
                <p className="text-center">No sizes available for this movie.</p>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!isAddingToSeedr && (
            <Button className="close-button" variant="secondary" onClick={handleCloseSeedrModal}>
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default App;
